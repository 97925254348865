import { Form } from './form';
export class Modal {
    get modalElement() {
        return document.getElementById('modal');
    }
    get preloaderElement() {
        return this.modalElement.querySelector('#modal__preloader');
    }
    get titleElement() {
        return this.modalElement.querySelector('#title');
    }
    get instance() {
        return this._form.instance;
    }
    get instanceSync() {
        return this._form.instanceSync;
    }
    set title(title) {
        this.titleElement.innerText = title;
    }
    get isOpened() {
        return !this.modalElement.hidden;
    }
    constructor(sdkWrapper) {
        this.sdkWrapper = sdkWrapper;
        this._form = new Form();
        this.onInit();
    }
    async onInit() {
        const sdk = await this.sdkWrapper.sdk;
        const instance = await sdk.createInstance({
            selector: '#modal__form',
            customLayoutType: 'extform',
        });
        this._form.registerInstance(instance);
        this.subscribeToModalClose(() => {
            sdk.enableNotify();
        });
        this.subscribeToModalOpen(() => {
            sdk.disableNotify();
        });
        instance.addFormHandler('link', event => {
            if (Object.values(event.contextParams).includes('logon')) {
                instance.loadData({ func: 'logon' });
                return false;
            }
        });
        instance.addFormHandler('button', params => {
            if (params.button.name !== 'cancel') {
                return;
            }
            const doc = instance.getData();
            const isRegisterFunc = doc?.$func === 'register';
            if (!isRegisterFunc) {
                return;
            }
            this.toggleModal(false);
            return false;
        });
        instance.addEventListener('docChange', (event) => {
            const doc = event.detail;
            const isEmpty = !doc;
            this.togglePreloader(isEmpty);
            this.title = doc?.messages?.msg.title || '';
            if (doc?.$func === 'register') {
                const firstPage = doc.metadata?.form?.page?.[0];
                const targetField = firstPage
                    ? firstPage.field
                    : doc.metadata?.form?.field;
                const logonLink = targetField?.find(field => field.$name === 'logon')
                    ?.link[0];
                if (logonLink) {
                    logonLink.$internal = 'yes';
                }
            }
        });
        instance.addHttpHandler({
            end: async (event) => {
                const doc = event.body?.doc;
                const isAuth = doc?.$func === 'auth' || doc?.$func === 'register.auth';
                const isContainAuth = 'auth' in doc;
                if (isAuth && isContainAuth) {
                    if (await this.isDesktopReachable(sdk)) {
                        this.toggleModal(false);
                    }
                    else {
                        window.location.href = '/billmgr?func=desktop';
                    }
                }
            },
        });
    }
    isDesktopReachable(sdk) {
        return new Promise(resolve => {
            sdk.http
                .get({
                requestParams: { func: 'desktop' },
                noRedirection: true,
            })
                .subscribe(response => {
                const isNotDesktop = response.doc.$func !== 'desktop';
                const isRedirectRequired = Boolean(response.doc.ok?.$type === 'form');
                const desktopIsNotReachable = isNotDesktop || isRedirectRequired;
                resolve(!desktopIsNotReachable);
            });
        });
    }
    togglePreloader(state) {
        this.preloaderElement.loading = state;
    }
    subscribeToModalClose(listener) {
        this.modalElement.addEventListener('close', listener);
    }
    subscribeToModalOpen(listener) {
        this.modalElement.addEventListener('openingStart', listener);
    }
    toggleModal(state) {
        // @WARN ispui-modal have very strange api! Be careful!
        if (state) {
            this.modalElement.hidden = false;
        }
        else {
            if (this.isOpened) {
                this.modalElement.closeModal();
            }
        }
        this.instanceSync?.clearData();
    }
    async openWithParams(params) {
        this.toggleModal(true);
        if (this.instanceSync) {
            this.instanceSync.loadData(params);
            return;
        }
        const instance = await this.instance;
        instance.loadData(params);
    }
}
