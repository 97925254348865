export class UserObservable {
    get role() {
        return this.currentUserRole;
    }
    get user() {
        return this.currentUser;
    }
    constructor(sdkWrapper) {
        this.sdkWrapper = sdkWrapper;
        this.userRoleListeners = [];
        this.userListeners = [];
        this.currentUserRole = 'pending';
        this.currentUser = null;
        this.onInit();
    }
    async onInit() {
        const sdk = await this.sdkWrapper.sdk;
        sdk.addUserListener(user => {
            this.currentUser = user;
            this.userListeners.forEach(cb => cb(user));
            const newUserRole = this.getUserRoleByLevel(user?.$level);
            if (newUserRole !== this.currentUserRole) {
                this.currentUserRole = newUserRole;
                this.userRoleListeners.forEach(cb => cb(this.currentUserRole));
            }
        });
    }
    getUserRoleByLevel(userLevel) {
        if (!userLevel) {
            return 'pending';
        }
        const level = parseInt(userLevel, 10);
        switch (true) {
            case level === 8:
                return 'guest';
            case level <= 24:
                return 'client';
            case level > 24:
                return 'admin';
            default:
                return 'guest';
        }
    }
    subscribeRole(listener) {
        this.userRoleListeners.push(listener);
        listener(this.currentUserRole);
    }
    unsubscribeRole(listener) {
        this.userRoleListeners = this.userRoleListeners.filter(l => l !== listener);
    }
    subscribeUser(listener) {
        this.userListeners.push(listener);
        listener(this.currentUser);
    }
    unsubscribeUser(listener) {
        this.userListeners = this.userListeners.filter(l => l !== listener);
    }
}
