export class GlobalBanner {
    get bannerElement() {
        return document.getElementById('global-banner');
    }
    constructor() {
        this.onInit();
    }
    onInit() {
        const cookieAccepted = localStorage.getItem('cookie_accepted');
        if (cookieAccepted) {
            this.bannerElement.remove();
            return;
        }
        this.bannerElement.style.display = 'block';
        this.bannerElement.addEventListener('close', () => {
            localStorage.setItem('cookie_accepted', 'yes');
            this.bannerElement.remove();
        });
    }
}
