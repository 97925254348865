import { Form } from './form';
export class MainForm {
    constructor(globalPreloader, userChange$, modal, languageService) {
        this.globalPreloader = globalPreloader;
        this.userChange$ = userChange$;
        this.modal = modal;
        this.languageService = languageService;
        this.currentUserRole = this.userChange$.role;
        this.form = new Form();
        this.onInit();
    }
    async onInit() {
        window.MainForm = this.form;
        const instance = await this.form.instance;
        instance.addFormHandler('button', params => {
            if (params.button.name !== 'register') {
                return;
            }
            const doc = instance.getData();
            const func = doc?.$func || '';
            const isOrderFuncRegexp = /(cart)|(order\.param)/;
            const isOrderFunc = isOrderFuncRegexp.exec(func);
            if (!isOrderFunc) {
                return;
            }
            this.modal.openWithParams({ func: 'register' });
            return false;
        });
        instance.addSetValuesListener(event => this.handleSetValuesEvent(event));
        this.modal.subscribeToModalClose(() => {
            this.updateForm(instance, this.userChange$.role);
        });
        this.userChange$.subscribeRole(userRole => {
            if (this.modal.isOpened) {
                return;
            }
            this.updateForm(instance, userRole);
        });
        instance.addEventListener('docChange', async (event) => {
            const doc = event.detail;
            if (!doc) {
                this.globalPreloader.togglePreloader(true);
                await instance.loadData({
                    func: 'v2.service.order.itemtype',
                });
                this.globalPreloader.togglePreloader(false);
                return;
            }
            if (doc.$stylesheet === 'login') {
                instance.setData(null);
                return;
            }
            const isFuncChanged = doc.$func !== this.currentFunc;
            if (isFuncChanged) {
                this.currentFunc = doc.$func;
                // @HACK need for scroll to top working on firefox
                setTimeout(() => {
                    window.scrollTo({
                        top: 0,
                    });
                });
            }
        });
        instance.addHttpHandler({
            end: event => {
                const doc = event.body?.doc;
                if (doc) {
                    this.languageService.setLang(doc.$lang);
                }
            },
        });
    }
    async updateForm(instance, newUserRole) {
        if (this.currentUserRole === 'pending' ||
            this.currentUserRole === newUserRole) {
            this.currentUserRole = newUserRole;
            return;
        }
        this.currentUserRole = newUserRole;
        this.globalPreloader.togglePreloader(true);
        const isEmpty = instance.getData() === null;
        if (isEmpty) {
            await instance.loadData({ func: 'v2.service.order.itemtype' });
        }
        else {
            await instance.reloadData();
        }
        this.globalPreloader.togglePreloader(false);
    }
    handleSetValuesEvent(event) {
        switch (true) {
            case event.blocking && event.type === 'start':
                this.globalPreloader.togglePreloader(true);
                break;
            case event.blocking && event.type === 'end':
                this.globalPreloader.togglePreloader(false);
                break;
        }
    }
    toggleVisibility(state) {
        if (this.form.instanceSync) {
            this.form.instanceSync.element.style.setProperty('display', state ? null : 'none');
        }
    }
}
