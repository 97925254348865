export class ContactButton {
    get buttonElement() {
        return document.getElementById('contact-button');
    }
    get contactElement() {
        return document.getElementById('contact');
    }
    constructor() {
        this.onInit();
    }
    onInit() {
        this.buttonElement.addEventListener('click', () => {
            this.contactElement.scrollIntoView({
                behavior: 'smooth',
            });
        });
    }
}
