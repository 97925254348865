export class UserMenu {
    get userNameElement() {
        return document.querySelector('#user-name-text');
    }
    constructor(sdkWrapper, globalPreloader, modal, userChange$, languageService) {
        this.sdkWrapper = sdkWrapper;
        this.globalPreloader = globalPreloader;
        this.modal = modal;
        this.userChange$ = userChange$;
        this.languageService = languageService;
        this.onInit();
    }
    onInit() {
        this.userChange$.subscribeRole(userRole => {
            this.updateMenuState(userRole);
        });
        this.userChange$.subscribeRole(userRole => {
            this.onUserOrLangChange(userRole);
        });
        this.languageService.subscribeLangChange(() => {
            this.onUserOrLangChange(this.userChange$.role);
        });
        document.addEventListener('DOMContentLoaded', async () => {
            const popup = (await this.getDropdownElement().getPopup());
            popup
                .querySelector('.vmenu__item_login')
                ?.addEventListener('click', () => {
                this.loginHandler();
            });
            popup
                .querySelector('.vmenu__item_logout')
                ?.addEventListener('click', () => {
                this.logoutHandler();
            });
            popup
                .querySelector('.vmenu__item_register')
                ?.addEventListener('click', () => {
                this.registerHandler();
            });
        });
    }
    onUserOrLangChange(userRole) {
        // we want to display username if he has it
        const userName = this.userChange$.user?.$name;
        // but not for 'guest' level, cause there is username='Guest' - useless info
        const isNotGuest = userRole !== 'guest';
        const messageToDisplay = isNotGuest && userName
            ? userName
            : this.languageService.message('personal_area');
        this.userNameElement.textContent = messageToDisplay;
    }
    async logoutHandler() {
        this.getDropdownElement().closeDropdown();
        // preloader hiding on error or on user change in main-form
        this.globalPreloader.togglePreloader(true);
        try {
            await this.sdkWrapper.sdkSync?.logout();
        }
        catch {
            this.globalPreloader.togglePreloader(false);
        }
    }
    loginHandler() {
        this.modal.openWithParams({ func: 'logon' });
        this.getDropdownElement().closeDropdown();
    }
    registerHandler() {
        this.modal.openWithParams({ func: 'register' });
        this.getDropdownElement().closeDropdown();
    }
    async updateMenuState(userRole) {
        const popup = await this.getDropdownElement().getPopup();
        if (!popup) {
            return;
        }
        const preloader = popup.querySelector('.vmenu__preloader');
        const authMenu = popup.querySelector('.vmenu_auth');
        const noAuthMenu = popup.querySelector('.vmenu_no-auth');
        if (!preloader || !authMenu || !noAuthMenu) {
            return;
        }
        preloader.loading = userRole === 'pending';
        preloader.style.display = userRole === 'pending' ? 'block' : 'none';
        authMenu.style.display =
            userRole === 'client' || userRole === 'admin' ? 'block' : 'none';
        noAuthMenu.style.display = userRole === 'guest' ? 'block' : 'none';
    }
    getDropdownElement() {
        return document.getElementById('user-menu-dropdown');
    }
}
