export class SDKWrapper {
    get sdk() {
        if (this._sdk) {
            return Promise.resolve(this._sdk);
        }
        return new Promise(resolve => {
            this.onSDKLoaded.push(resolve);
        });
    }
    get sdkSync() {
        return this._sdk;
    }
    constructor() {
        this.onSDKLoaded = [];
        this._sdk = null;
        this.onInit();
    }
    onInit() {
        if (window.ISPPublicFormSDK) {
            this.setSDK(window.ISPPublicFormSDK);
            return;
        }
        window.addEventListener('ISPPublicFormSDKLoaded', (event) => {
            this.setSDK(event.detail);
        });
    }
    setSDK(sdk) {
        this._sdk = sdk;
        this.onSDKLoaded.forEach(cb => cb(sdk));
    }
}
