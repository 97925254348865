const MEDIA_BREAK_POINTS = {
    ['xxs']: 0,
    ['xs']: 320,
    ['sm']: 480,
    ['md']: 640,
    ['lg']: 960,
    ['xl']: 1280,
    ['xxl']: 1440,
};
/**
 * Check match media query
 *
 * @param breakPoint
 */
export function matchMediaBreakpointUp(breakPoint) {
    return window.matchMedia(`(min-width: ${MEDIA_BREAK_POINTS[breakPoint]}px)`)
        .matches;
}
