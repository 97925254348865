export class Stub {
    get stubElement() {
        return document.getElementById('initial-preloader__stub');
    }
    constructor(mainForm, userChange$) {
        this.mainForm = mainForm;
        this.userChange$ = userChange$;
        this.onInit();
    }
    async onInit() {
        const instance = await this.mainForm.form.instance;
        instance.addEventListener('docLoadError', () => {
            this.toggleStub(true);
            this.mainForm.toggleVisibility(false);
        });
        this.userChange$.subscribeRole(userRole => {
            if (userRole === 'admin') {
                this.toggleStub(true);
                this.mainForm.toggleVisibility(false);
            }
        });
        instance.addEventListener('docChange', () => {
            const doc = instance.getData();
            const isErrorDoc = doc?.error || doc?.$func === 'error';
            if (isErrorDoc) {
                this.toggleStub(true);
                this.mainForm.toggleVisibility(false);
                return;
            }
            if (doc !== null && this.userChange$.role !== 'admin') {
                this.toggleStub(false);
                this.mainForm.toggleVisibility(true);
                return;
            }
        });
    }
    toggleStub(state) {
        this.stubElement.classList.toggle('hidden', !state);
    }
}
