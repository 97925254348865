export class OrderButton {
    get orderButton() {
        return document.getElementById('order-button');
    }
    constructor(mainForm) {
        this.mainForm = mainForm;
        this.onInit();
    }
    async onInit() {
        const instance = await this.mainForm.form.instance;
        this.orderButton.onclick = () => {
            instance.loadData({ func: 'service.order.itemtype' });
            window.scrollTo({
                behavior: 'smooth',
                top: 0,
            });
        };
    }
}
