import { applyPolyfills as applyAnnouncementPolyfills, defineCustomElements as defineAnnouncementElements, } from '@ispui/announcement/loader';
import { applyPolyfills as applyDropdownPolyfills, defineCustomElements as defineDropdownElements, } from '@ispui/dropdown/loader';
import { applyPolyfills as applyModalPolyfills, defineCustomElements as defineModalElements, } from '@ispui/modal/loader';
import { applyPolyfills as applyPreloaderRoundPolyfills, defineCustomElements as definePreloaderRoundElements, } from '@ispui/preloader-round/loader';
import { applyPolyfills as applyProgressBarPolyfills, defineCustomElements as defineProgressBarElements, } from '@ispui/progress-bar/loader';
/**
 * Define custom element from ispui
 */
export function defineISPUICustomElements() {
    return Promise.all([
        applyDropdownPolyfills().then(() => {
            defineDropdownElements();
        }),
        applyProgressBarPolyfills().then(() => {
            defineProgressBarElements();
        }),
        applyPreloaderRoundPolyfills().then(() => {
            definePreloaderRoundElements();
        }),
        applyModalPolyfills().then(() => {
            defineModalElements();
        }),
        applyAnnouncementPolyfills().then(() => {
            defineAnnouncementElements();
        }),
    ]).then(() => undefined);
}
