/**
 * App language/i18n service
 */
export class LanguageService {
    constructor() {
        /** Messages from BE */
        this.messages = window.ShowroomMessages;
        /** Current app lang */
        this.lang = this.getInitialLang();
        /** Callbacks for language change */
        this.onLangChangeCallbacks = [];
        /**
         * Message binding elements
         *
         * @WARN you shouldn't use getter, because ispui-dropdown cut out some elements from DOM and you'll never retrieve them!
         */
        this.messageElements = Array.from(document.querySelectorAll('[data-msg]'));
        this.observeLangChange();
    }
    observeLangChange() {
        this.updateAppMessages();
        this.subscribeLangChange(() => this.updateAppMessages());
    }
    /**
     * Update all messages in app
     */
    updateAppMessages() {
        this.messageElements.forEach(element => {
            const key = element.dataset.msg || '';
            const message = this.message(key);
            element.innerText = message;
            // small hack for anchor elements
            if (element.dataset.msgSetEmail !== undefined) {
                element.href = `mailto:${message}`;
            }
        });
    }
    /**
     * Retrieve current language with fallback to 'en'
     */
    getInitialLang() {
        // browser language see https://datatracker.ietf.org/doc/html/rfc5646#section-2.1
        const browserLanguage = navigator.language;
        const subTags = browserLanguage.split('-');
        const currentLanguage = (subTags[0] || 'en');
        return this.messages[currentLanguage] ? currentLanguage : 'en';
    }
    /**
     * Get message by key
     *
     * @param key
     */
    message(key) {
        return this.messages[this.lang][key] || '';
    }
    /**
     * Set app lang
     *
     * @param lang
     */
    setLang(lang) {
        const prevLang = this.lang;
        this.lang = this.messages[lang] ? lang : 'en';
        if (prevLang !== this.lang) {
            this.onLangChangeCallbacks.forEach(callback => callback(this.lang));
        }
    }
    /**
     * Set callback for language change
     *
     * @param callback
     */
    subscribeLangChange(callback) {
        this.onLangChangeCallbacks.push(callback);
    }
}
