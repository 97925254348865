import { BasketButton } from './app/basket-button';
import { ContactButton } from './app/contact-button';
import { GlobalBanner } from './app/global-banner';
import { GlobalPreloader } from './app/global-preloader';
import { InitialPreloader } from './app/initial-preloader';
import { LanguageService } from './app/language.service';
import { MainForm } from './app/main-form';
import { Modal } from './app/modal';
import { OrderButton } from './app/order-button';
import { PanelMenu } from './app/panel-menu';
import { SDKWrapper } from './app/sdk-wrapper';
import { Stub } from './app/stub';
import { UserMenu } from './app/user-menu';
import { UserObservable } from './app/user-observable';
import { defineISPUICustomElements } from './define-ispui-custom-elements';
(async () => {
    // @WARN create language service before ispui initialization. Because some ispui components mutate DOM
    const languageService = new LanguageService();
    await defineISPUICustomElements();
    const sdkWrapper = new SDKWrapper();
    const globalPreloader = new GlobalPreloader();
    const userChange$ = new UserObservable(sdkWrapper);
    const modal = new Modal(sdkWrapper);
    const mainForm = new MainForm(globalPreloader, userChange$, modal, languageService);
    new GlobalBanner();
    new Stub(mainForm, userChange$);
    new InitialPreloader(mainForm);
    new BasketButton(sdkWrapper, mainForm, userChange$, modal, languageService);
    new OrderButton(mainForm);
    new ContactButton();
    new UserMenu(sdkWrapper, globalPreloader, modal, userChange$, languageService);
    new PanelMenu(sdkWrapper, modal, mainForm, globalPreloader, userChange$);
})();
