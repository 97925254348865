export class InitialPreloader {
    get preloaderElement() {
        return document.getElementById('initial-preloader');
    }
    constructor(mainForm) {
        this.mainForm = mainForm;
        this.onInit();
    }
    async onInit() {
        const instance = await this.mainForm.form.instance;
        instance.addEventListener('docChange', () => {
            this.togglePreloader(false);
        }, {
            once: true,
        });
        instance.addEventListener('docLoadError', () => {
            this.togglePreloader(false);
        }, {
            once: true,
        });
    }
    togglePreloader(state) {
        this.preloaderElement.classList.toggle('hidden', !state);
    }
}
