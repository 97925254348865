export class Form {
    constructor() {
        this.onInstanceCreate = [];
        this._instance = null;
    }
    get instance() {
        if (this._instance) {
            return Promise.resolve(this._instance);
        }
        return new Promise(resolve => {
            this.onInstanceCreate.push(resolve);
        });
    }
    get instanceSync() {
        return this._instance;
    }
    registerInstance(instance) {
        this._instance = instance;
        this.onInstanceCreate.forEach(cb => cb(instance));
    }
}
