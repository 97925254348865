import { matchMediaBreakpointUp } from './utils/media';
export class PanelMenu {
    get panelMenuElement() {
        return document.querySelector('.panel-menu');
    }
    get userNameElement() {
        return document.querySelector('.panel-menu__user-name');
    }
    get headerMenuButton() {
        return document.querySelector('.header__menu-button');
    }
    get miniHeaderIcon() {
        return document.querySelector('.header__icon_mini');
    }
    get crossHeaderIcon() {
        return document.querySelector('.header__icon_cross');
    }
    get authGroup() {
        return document.querySelector('.panel-menu__group_auth');
    }
    get noAuthGroup() {
        return document.querySelector('.panel-menu__group_no-auth');
    }
    get panelOrderButton() {
        return document.querySelector('.panel-menu__button_order');
    }
    get panelContactButton() {
        return document.querySelector('.panel-menu__button_contact');
    }
    get panelLoginButton() {
        return document.querySelector('.panel-menu__button_login');
    }
    get panelLogoutButton() {
        return document.querySelector('.panel-menu__button_logout');
    }
    get panelRegisterButton() {
        return document.querySelector('.panel-menu__button_register');
    }
    get panelBasketButton() {
        return document.querySelector('.panel-menu__button_basket');
    }
    constructor(sdkWrapper, modal, mainForm, globalPreloader, userChange$) {
        this.sdkWrapper = sdkWrapper;
        this.modal = modal;
        this.mainForm = mainForm;
        this.globalPreloader = globalPreloader;
        this.userChange$ = userChange$;
        this.isOpen = false;
        this.onInit();
    }
    async onInit() {
        window.addEventListener('resize', () => {
            if (matchMediaBreakpointUp('lg')) {
                this.toggleOpenState(false);
            }
        });
        this.userChange$.subscribeRole(userRole => {
            this.updateMenuGroups(userRole);
        });
        this.userChange$.subscribeRole(userRole => {
            if (userRole !== 'guest' && this.userChange$.user?.$name) {
                this.userNameElement.style.display = 'block';
                this.userNameElement.textContent = this.userChange$.user?.$name;
            }
            else {
                this.userNameElement.style.display = 'none';
                this.userNameElement.textContent = '';
            }
        });
        this.headerMenuButton.addEventListener('click', () => {
            this.toggleOpenState();
        });
        this.panelLogoutButton.addEventListener('click', () => {
            this.logoutButtonHandler();
        });
        this.panelContactButton.addEventListener('click', () => {
            this.contactButtonHandler();
        });
        this.panelLoginButton.addEventListener('click', () => {
            this.loginButtonHandler();
        });
        this.panelRegisterButton.addEventListener('click', () => {
            this.registerButtonHandler();
        });
        const instance = await this.mainForm.form.instance;
        this.panelOrderButton.addEventListener('click', () => {
            this.orderButtonHandler(instance);
        });
        this.panelBasketButton.addEventListener('click', () => {
            this.basketButtonHandler(instance);
        });
    }
    orderButtonHandler(instance) {
        this.toggleOpenState(false);
        instance.loadData({ func: 'service.order.itemtype' });
    }
    contactButtonHandler() {
        this.toggleOpenState(false);
    }
    async logoutButtonHandler() {
        this.toggleOpenState(false);
        this.globalPreloader.togglePreloader(true);
        try {
            await this.sdkWrapper.sdkSync?.logout();
        }
        catch (e) {
            console.error(e);
        }
        this.globalPreloader.togglePreloader(false);
    }
    basketButtonHandler(instance) {
        this.toggleOpenState(false);
        instance.loadData({ func: 'cart' });
    }
    loginButtonHandler() {
        this.toggleOpenState(false);
        this.modal.openWithParams({ func: 'logon' });
    }
    registerButtonHandler() {
        this.toggleOpenState(false);
        this.modal.openWithParams({ func: 'register' });
    }
    updateMenuGroups(userRole) {
        this.authGroup.style.display =
            userRole === 'client' || userRole === 'admin' ? 'flex' : 'none';
        this.noAuthGroup.style.display = userRole === 'guest' ? 'flex' : 'none';
    }
    toggleOpenState(isOpen = !this.isOpen) {
        this.isOpen = isOpen;
        // hide scroll bar on page
        document.body.classList.toggle('global-overflow-hidden', isOpen);
        this.miniHeaderIcon.style.display = isOpen ? 'none' : 'block';
        this.crossHeaderIcon.style.display = isOpen ? 'block' : 'none';
        this.panelMenuElement.classList.toggle('panel-menu__opened', isOpen);
    }
}
