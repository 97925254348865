export class BasketButton {
    get buttonElement() {
        return Array.from(document.querySelectorAll('.basket-button'));
    }
    get buttonText() {
        return Array.from(document.querySelectorAll('.basket-button-text'));
    }
    constructor(sdkWrapper, mainForm, userChange$, modal, languageService) {
        this.sdkWrapper = sdkWrapper;
        this.mainForm = mainForm;
        this.userChange$ = userChange$;
        this.modal = modal;
        this.languageService = languageService;
        this.currentUserRole = this.userChange$.role;
        this.currentBasketInfo = null;
        this.onInit();
    }
    async onInit() {
        const sdk = await this.sdkWrapper.sdk;
        sdk.addBasketListener((event) => {
            this.handleBasketChangeEvent(event);
        });
        this.languageService.subscribeLangChange(() => {
            if (this.currentBasketInfo) {
                this.updateBasketButtonText(this.currentBasketInfo);
            }
        });
        this.modal.subscribeToModalClose(() => {
            this.updateBasketInfo(sdk, this.userChange$.role);
        });
        this.userChange$.subscribeRole(userRole => {
            if (this.modal.isOpened) {
                return;
            }
            this.updateBasketInfo(sdk, userRole);
        });
        const instance = await this.mainForm.form.instance;
        this.buttonElement.forEach(button => button.addEventListener('click', event => {
            event.preventDefault();
            this.handleButtonClick(instance);
        }));
    }
    updateBasketInfo(sdk, userRole) {
        if (this.currentUserRole === userRole) {
            return;
        }
        this.currentUserRole = userRole;
        if (this.currentUserRole === 'admin') {
            this.handleBasketChangeEvent(null);
        }
        else {
            sdk.updateBasketInfo();
        }
    }
    handleBasketChangeEvent(event) {
        if (!event) {
            this.toggleButtonVisibility(false);
            return;
        }
        const { count, amount, currency } = event;
        this.currentBasketInfo = { count, amount, currency };
        const isEmpty = parseInt(count, 10) === 0;
        this.toggleButtonVisibility(!isEmpty);
        if (!isEmpty) {
            this.updateBasketButtonText(this.currentBasketInfo);
        }
    }
    handleButtonClick(instance) {
        instance.loadData({ func: 'cart' });
    }
    updateBasketButtonText(info) {
        const { count, amount, currency } = info;
        const text = `${count} ${this.languageService.message('amount_short')}, ${amount} ${currency}`;
        this.buttonText.forEach(buttonText => {
            buttonText.innerText = text;
        });
    }
    toggleButtonVisibility(state) {
        this.buttonElement.forEach(button => {
            button.classList.toggle('hidden', !state);
        });
    }
}
